import React from "react";
import { graphql } from "gatsby";
import BlogElem from "../components/blog/blog";
import Posts from "../components/blog/posts";
import CategoryMenu from "../components/blog/category-menu";
import Seo from "../components/global/seo";
import convertId from "../utilities/convert-id";
import Content from "../components/utilities/content";
import CmsImage from "../components/utilities/cms-image";
import IpTracker from "../components/utilities/ip-tracker";

const Tag = ({
  data: {
    allWpPost: posts,
    allWpCategory: categories,
    wpPage: page,
    wpTag: tag,
    wp: {
      themeSettings: { themeSettings },
    },
  },
  pageContext: { totalPages, currentPage, baseUri },
}) => {
  return (
    <>
      {tag.page && (
        <Seo
          title={tag.page.metaTitle ? tag.page.metaTitle : tag.name}
          description={
            tag.page.metaDescription
              ? tag.page.metaDescription
              : tag.description
          }
          themeSettings={themeSettings}
        />
      )}

      {page.sections.sections &&
        page.sections.sections.map((section) => (
          <section
            id={convertId(section.name)}
            className="page-title section-title-with-background-image"
          >
            <CmsImage className="section-img" image={section.image} />
            <div className="container">
              <div className="sz wrapper">
                <Content className="content-title-bg-image">
                  {section.content}
                </Content>
              </div>
            </div>
          </section>
        ))}

      <CategoryMenu categories={categories.edges} />
      <BlogElem>
        <Posts
          posts={posts}
          totalPages={totalPages}
          currentPage={currentPage}
          baseUri={baseUri}
          type="tag"
        />
      </BlogElem>
      <IpTracker />
    </>
  );
};

export const pageQuery = graphql`
  query Tag($id: String!, $perPage: Int!, $skipItems: Int!) {
    wpTag(id: { eq: $id }) {
      name
      description
      page {
        fieldGroupName
        metaTitle
        metaDescription
        pageCssOverrides
      }
      id
      slug
      uri
    }

    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
          logo {
            localFile {
              publicURL
            }
          }
        }
      }
    }

    allWpCategory(sort: { order: ASC, fields: categorySettings___order }) {
      edges {
        node {
          id
          uri
          slug
          name
          page {
            fieldGroupName
            metaTitle
            metaDescription
            pageCssOverrides
          }
          parentId
        }
      }
    }

    wpPage(isPostsPage: { eq: true }) {
      uri
      slug
      id
      title
      sections {
        fieldGroupName
        sections {
          ... on WpPage_Sections_Sections_SectionTitleWithBackgroundImage {
            fieldGroupName
            name
            content
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
        }
      }
    }

    allWpPost(
      sort: { order: DESC, fields: date }
      limit: $perPage
      skip: $skipItems
      filter: { tags: { nodes: { elemMatch: { id: { eq: $id } } } } }
    ) {
      edges {
        node {
          id
          title
          uri
          date(fromNow: true, formatString: "MMM DD YYYY")
          categories {
            nodes {
              id
              uri
              slug
              name
            }
          }
          tags {
            nodes {
              id
              uri
              slug
              name
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 760, height: 427)
                }
                extension
                publicURL
              }
            }
          }
          excerpt
        }
      }
    }
  }
`;

export default Tag;
